#sidebar #avatar {
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
  > a {
    width: initial !important;
    height: initial !important;
    border-radius: initial;
    border: initial;
    overflow: initial;
    transform: initial;
    -webkit-transition: initial;
    -moz-transition: initial;
    transition: initial;
  }
  img {
    width: initial;
    height: initial;
    max-height: 10rem;
    -webkit-transition: initial;
    -moz-transition: initial;
    transition: initial;

    &:hover {
      -ms-transform: initial;
      -moz-transform: initial;
      -webkit-transform: initial;
      transform: initial;
    }
  }
}

a[href^="/"], a[href^="http://"], a[href^="https://"] {
  &:not(:where(
    /* exclude links with aria-label attribute */
    [aria-label],
    /* exclude path-relative but not protocol-relative */
    [href^="/"]:not([href^="//"]),
    /* exclude CCC domains */
    [href^="https://ccc.ac"],
    [href^="https://ccc.de"],
    /* exclude subdomains. note: not bulletproof, these strings can appear anywhere in the URL */
    [href$=".ccc.ac"], [href*=".ccc.ac/"],
    [href$=".ccc.de"], [href*=".ccc.de/"],
  ))::after {
    content: '' / 'externer Link';
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    margin-left: 0.25em;
    background-size: 100%;
    background-image: url("../img/external-link-alt-solid.svg");
    // thanks to https://css-irl.info/styling-external-links-with-attribute-selectors/
  }
}

#search-results tr {
  &:hover {
    background: #eee;
  }
  td {
    padding: 0;
    a {
      display: block;
      padding: 10px 15px;
      box-sizing: border-box;
      &:hover {
        text-decoration: none;
        color: #2a7ae2;
      }
    }
    i {
      display: block;
      padding: 10px 15px;
    }
  }
}

div.deprecated_notice {
  color: #f00;
  border-color: #f00;
  border-style: dashed;
  border-width: 2px;
  padding: 1em;
  text-align: center;
  margin-bottom: 1em;
  font-weight: bold;
  font-size: 110%;
}

#status {
  border-radius: 1rem;
  line-height: 1.5;
  padding: 0.18rem 1rem;
  display: none;
  font-weight: bold;
  text-align: center;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  background-color: #ffa500;

  &[data-status=open] {background-color: #0F700A;}
  &[data-status=closed] {background-color: #890C0F;}
  &[data-status=private] {background-color: #1E90FF;}
}

// Hide status when search bar expands on mobile
#topbar-title.unloaded + #status {
  display: none;
}

// prevent jumping around of #status, which is inserted next to #breadcrumb
#breadcrumb {
  width: initial;
}

#announcements {
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-bottom: 1rem;

  th {
    background: var(--card-border-color);
  }

  th, td {
    padding: 10px 15px;
    border: 1px solid rgba(128, 128, 128, 0.2);
  }

  tbody tr {
    background-color: initial;
    
    &:nth-child(2n) {
      background: var(--card-border-color);
    }
  }

  td:nth-child(-n+2) {
    white-space: nowrap;
  }
}

#openingtable {
  min-width: initial;

  td {
    font-weight: bold;
    padding-left: 0;
  }
}

b.private {
  background: #1e90ff;
  padding: 0 0.2rem;
  color: white;
  border-radius: 0.3rem;
}

.leaflet-container {
  margin-bottom: 15px;

  img[src] {
    margin: initial;
  }
}

.leaflet-tooltip {
  background-color: #333 !important;
  border-color: #333 !important;
  color: white !important;
  font-weight: bold;

  &.leaflet-tooltip-top::before {border-top-color: #333 !important}
  &.leaflet-tooltip-bottom::before {border-bottom-color: #333 !important}
  &.leaflet-tooltip-left::before {border-left-color: #333 !important}
  &.leaflet-tooltip-right::before {border-right-color: #333 !important}
}

input {
  border-radius: 0;
  margin: 0;
  border: 1px solid #d3d3d3;
  padding: 6px;

  &[type=submit] {
    background: #eee;

    &:hover {
      background: #ddd;
    }
  }
}