/*
  If the number of TAB files has changed, the following variable is required.
  And it must be defined before `@import`.
*/
$tab-count: 8; // plus 1 for home tab

@import "jekyll-theme-chirpy";

/* append your custom style below */

@import "custom.scss";